/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
$('.owl-carousel').owlCarousel({
      nav:false,
      loop: true,
      items: 1,
      lazyLoad:true,
      autoplay:true,
      autoplayHoverPause:true,
              animateIn:'fadeIn',
            animateOut: 'fadeOut'
      
    });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from archive.
    'archive': {
      init: function() {
        // JavaScript to be fired on the archive
        $('.lazy.primary').addClass('not-loaded');
$('img.lazy.secondary').addClass('not-loaded');
function resizeBlankImagesPrimary() {
  $('.primary.not-loaded').each(function () {
    var originalWidth = $(this).attr('width');
    var originalHeight = $(this).attr('height');
    var ratioH = originalWidth/originalHeight;
    var width = $(this).width();
    var height = width/ratioH;
    $(this).height(height);
  });
}
function resizeBlankImagesSecondary() {
  $('img.secondary.not-loaded').each(function () {
    var originalWidth = $(this).attr('width');
    var originalHeight = $(this).attr('height');
    var ratioH = originalWidth/originalHeight;
    var width = $(this).width();
    var height = width/ratioH;
    $(this).height(height);
  });
}
var $container = $('.masonry-container');
$container.imagesLoaded( function () {
      resizeBlankImagesPrimary();
  $container.masonry({
    columnWidth: '.item',
    itemSelector: '.item'
  });

  $('.lazy.primary.not-loaded').lazyload({
    effect: 'fadeIn', 
    threshold : 600,
    load: function() {
      $(this).removeClass("not-loaded");
      $(this).addClass("loaded");
    }
  });
});
$(".modal.first").each(function() {
  var $this = $(this);
  $this.on('shown.bs.modal', function () {
    var $containermodal = $('.modal.first.in .masonry-container-modal');
    $containermodal.imagesLoaded( function () {
      resizeBlankImagesSecondary();
      $containermodal.masonry({
        columnWidth: '.item-modal',
        itemSelector: '.item-modal'
      });
    });
    $('.modal.first.in img.lazy.secondary.not-loaded').lazyload({
     container:$('.modal.first.in'),
     effect: 'fadeIn', 
     threshold : 600,
     load: function() {
      $(this).removeClass("not-loaded");
      $(this).addClass("loaded");
      $(this).css('height', 'auto');
    }
  });
    $('.modal.first.in').trigger("scroll");
  });
   var hash = window.location.hash;
  $('.taphover').click(function (e) {
    window.location.hash = this.hash;
  });
});
$(".modal.bis").each(function() {
  var $this = $(this);
  $this.on('show.bs.modal', function(e) {        
    var $owlfull=$this.find('.full.owl-carousel');
    var windowheight=$(window).outerHeight();
    var modalheaderheight=$this.find('.modal-header').outerHeight();
    var modalfooterheight=$this.find('.modal-footer').outerHeight();
    var owlheight= windowheight - 120;
    $owlfull.css('height',owlheight+'px');


    
$owlfull.owlCarousel({
      nav:true,
      loop: true,
      items: 1,
      URLhashListener:true,
      startPosition: 'URLHash',
      lazyLoad:true,
      responsive:{
        0:{
          items:1,
          stagePadding: 60,
          margin:10
        },
        600:{
          items:1,
          stagePadding: 100,
          margin:10
        },
        1000:{
          items:1,
          stagePadding: 200,
          margin:10
        },
        1200:{
          items:1,
          stagePadding: 250,
          margin:10
        },
        1400:{
          items:1,
          stagePadding: 300,
          margin:10
        },
        1600:{
          items:1,
          stagePadding: 350,
          margin:10
        },
        1800:{
          items:1,
          stagePadding: 400,
          margin:10
        }
      },

      navText:['<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>','<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>']
    });
    $owlfull.on('mousewheel', '.owl-stage', function (e) {
      if (e.deltaY>0) {
        $owlfull.trigger('next.owl');
      } else {
        $owlfull.trigger('prev.owl');
      }


      e.preventDefault();

    });

    $(window).on("resize scroll",function(e){
      var windowheight=$(window).outerHeight();
      var modalheaderheight=$('.modal-header').outerHeight();
      var modalfooterheight=$('.modal-footer').outerHeight();
      var owlheight= windowheight - 120;
      $owlfull.css('height',owlheight+'px');
    });
  });
$this.on('hidden.bs.modal', function(e) { 
  history.pushState('', document.title, window.location.pathname);
  $('body').addClass("modal-open");
});
});
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
